<template>
  <Container :padding="false">
    <template v-if="checkResponseStatus && !isLoading">
      <div class="full_image mb_6rem">
        <div v-html="content"></div>
      </div>
    </template>
    <NoData v-else-if="!checkResponseStatus && !isLoading"></NoData>
  </Container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Container from "../components/Container";
import NoData from "@/components/NoData";
export default {
  components: {
    NoData,
    Container,
  },
  data() {
    return {};
  },
  watch: {
    list: {
      handler: function(newVal) {
        this.changeSpinnerStatus(true);
        if (newVal) {
          setTimeout(() => {
            this.changeSpinnerStatus(false);
          }, 800);
        } else {
          this.changeSpinnerStatus(false);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("spinner", ["isLoading"]),
    ...mapState("help", ["helpData"]),
    id() {
      return +this.$route.query.id;
    },
    list() {
      if (!this.helpData && !this.id) {
        return [];
      } else {
        return this.helpData.filter((data, index) => {
          if (index === this.id) {
            this.changeHeader(data.title);
            return data;
          }
        });
      }
    },
    content() {
      if (this.list.length === 0) return;
      return this.list[0].content;
    },
    checkResponseStatus() {
      return this.list && this.list.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions("help", ["getHelp"]),
    ...mapActions("spinner", ["changeSpinnerStatus"]),
    ...mapActions("header", ["changeHeader"]),
  },
  created() {
    this.getHelp({
      code: "kmbzzx",
      page: 1,
      limit: 20,
    });
  },
};
</script>

<style lang="scss" scoped>
.full_image {
  width: 100%;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
</style>
